:root {
  --gray2: #F5f6f8;
  --darkest: #282323;
  --teal: #2b7ca6;
  --lightest: #fdfdfd;
  --gray3: #Eff1f2;
  --gray4: #aebbc3;
  --accent: #0071bc;
  --darkgray: #5D6468;
  --gray5: #fafafa;
  --gray6: #DDE2E6;
}

@import "~antd/dist/antd.less";

body {
  font-family: 'Lato', sans-serif !important;
}

#root {
  height: 100%;
  font-family: 'Lato', sans-serif !important;
}

.button-back {
  background-color: var(--accent);
}

.container-options {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15.875rem, 1fr));
  gap: 1.5rem;
  user-select: none;

  .item {
    background-color: white;
    position: relative;
    padding: 1.8rem;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
    word-wrap: break-word;
    overflow: hidden;
    border-radius: 6px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.04);

    h3 {
      font-weight: bold;
      font-size: 1.4em;
    }

    p {
      font-size: 1em;
      color: #9497A1;
    }

    i {
      font-size: 1.8rem;
      color: var(--teal);
    }

    .icon {
      height: 2rem;
      width: 2rem;
    }

    .icon-lock-style {
      position: absolute;
      bottom: 1rem;
      right: 1.5rem;
      font-size: 1.2em;
      color: var(--teal);
    }

    &:hover {
      transform: scale(1.03);
      cursor: pointer;
    }
  }

  .disabled-item {
    box-shadow: none;

    i {
      filter: grayscale(100%);
    }

    &:hover {
      transform: none;
      cursor: not-allowed;
    }
  }
  .disabled-item::after {
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .5;
    background-color: var(--gray6);
    position: absolute;
    z-index: 1;
    cursor: not-allowed;
  }


  .paths-card {
    height: 7rem;
  }
}


@primary-color: #307097;