//HEADER COMPONENT
.header {
  display: flex;
  align-items: center;
  height: 5.2rem;
  justify-content: space-between;
  user-select: none;
  // text-transform: uppercase;

  .content-title {
    display: flex;
    gap: .5rem;
    align-items: center;
    font-size: 12px;
    color: #2B7CA6;

    .icon {
      width: .97rem;
      height: .95rem;
      margin: 0 .5rem .16rem 0;
    }

    .title {
      color: #2B7CA6;
      font-size: 1.1em;
      display: flex;
      flex-wrap: wrap;
      flex-basis: auto;
      align-items: center;
      font-weight: 400;
      line-height: 14px;
      text-align: center;
      word-wrap: break-word;

      .container-path {
        display: flex;
        align-items: center;

        .path {
          cursor: pointer;
          margin: .2rem 0;

          &:hover {
            text-decoration: underline #2B7CA6;
          }
        }

        .no-path {
          cursor: default;
        }
      }

    }

    @media (max-width:430px) {
      .title {
        font-size: .9em;
      }
    }
  }
}

.sub-header {
  margin-bottom: 1.7rem;
  margin-top: -.2rem;
  user-select: none;
  display: flex;
  justify-content: space-between;

  .sub-title {
    color: var(--gray4);
    font-size: 1.25em;
    margin-bottom: 0.2rem;
  }

  .title {
    color: var(--darkgray);
    font-size: 1.7em;
    font-weight: 500;

  }
}

@media (max-width:430px) {
  .sub-header {
    font-size: .9em;
    margin-top: .5rem;
  }
}

///FLOATING MENU STYLES//
:root {
  --widthFloating: 25rem;
  --marginTop: 9%;
  --marginRight: 0;
}

.container-size {
  width: 0;
}

.icon-size {
  transform: translateX(0);
}

.container-floating-menu {
  height: auto;
  word-break: normal;
  word-wrap: normal;
  position: absolute;
  z-index: 10;
  right: var(--marginRight);
  top: var(--marginTop);
  background-color: white;
  border-radius: 6px;
  border: .5px solid var(--gray6);
  overflow: hidden;

  .options {
    display: flex;
    flex-direction: column;
    padding: 1rem 1.5rem 2rem 1.5rem;
    background-color: white;
  }

}

.open-icon-floating-menu {
  width: 2.6rem;
  height: 2.6em;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--gray6);
  background-color: white;
  position: absolute;
  z-index: 11;
  right: var(--marginRight);
  top: var(--marginTop);
  cursor: pointer;

  i {
    font-size: 1.25rem;

    &:hover {
      transform: scale(1.1);
    }
  }


}

.open-floating-menu {
  animation: openMenu .5s ease-out;
  width: var(--widthFloating);
}

.close-floating-menu {
  animation: closeMenu .5s ease-in;
  width: var(--marginRight);
}

.move-icon-open {
  animation: openicon .5s ease-out;
  transform: translateX(calc(var(--widthFloating) * -1));
}

.move-icon-close {
  animation: closeicon .5s ease-in;
  transform: translateX(var(--marginRight));
}

@keyframes openicon {
  from {
    transform: translateX(var(--marginRight));
  }

  to {
    transform: translateX(calc(var(--widthFloating) * -1));
  }
}

@keyframes closeicon {
  from {
    transform: translateX(calc(var(--widthFloating) * -1));
  }

  to {
    transform: translateX(var(--marginRight));
  }
}

@keyframes openMenu {

  from {
    width: var(--marginRight);
  }

  to {
    width: var(--widthFloating);
  }
}

@keyframes closeMenu {
  from {
    width: var(--widthFloating);
  }

  to {
    width: var(--marginRight);
  }
}
@primary-color: #307097;