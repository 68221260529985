@media only screen and (max-width: 600px) {
  .menuBarTop {
    max-width: 100px;
  }
}

@media only screen and (min-width: 600px) {
  .menuBarTop {
    width: 360px;
  }
}

.user-option {
  display: flex;
  user-select: none;
  position: absolute;
  right: 3rem;
  top: 1.7rem;
  gap: .5rem;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  cursor: pointer;

  i {
    align-self: baseline;
    margin-top: .25rem;
    transition: all .2s ease-in-out;
  }

}

.box-options {
  width: 12rem;
  height: auto;
  background-color: rgb(248, 248, 248);
  box-shadow: 5px 5px 8px -5px rgb(163, 163, 163);
  position: absolute;
  top: 4rem;
  right: 3rem;
  z-index: 10;
  padding: .9rem 1.2rem;
  word-wrap: break-word;
  color: var(--darkgray);

  p {
    display: flex;
    align-items: center;
    gap: .8rem;
    cursor: pointer;
    padding-bottom: .5rem;
    border-bottom: 1px solid var(--gray4);

    &:hover span {
      text-decoration: underline;
    }
    &:not(:has(span)):hover{
      text-decoration: underline;
    }

    .rotate {
      animation: rotate infinite 1s ease-in-out;
    }

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100%{
        transform: rotate(360deg);
      }
    }
  }
}

.hidden {
  display: none;
}

.show {
  display: block;
}
@primary-color: #307097;