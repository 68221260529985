.container-login {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../../../public/LoginBG.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: border-box;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .card-container-form {
    min-width: 44.12rem;
    min-height: 28.9rem;
    position: relative;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
      max-width: 12.6rem;
    }

    .form {
      width: 30rem;
      .btn-loging{
        background-color: var(--accent);
        color: white;
      }
    }

    .privacy-content {
      color: var(--darkgray);
      width: 100%;
      font-size: .82em;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      p:first-child{
        margin-bottom: 0;
      }
      span{
        color: var(--accent);
        cursor: pointer;
      }
    }
  }

  @media (max-width:1024px) {
    .card-container-form {
      min-width: 50%;
      min-height: 10vh;

      .logo {
        max-width: 15vh;
      }
    }
  }

  @media (max-width:600px) {
    .card-container-form {
      .form {
        width: 100%;
      }
      .privacy-content {
        width: 90%;
        margin-left: 5%;
        align-items: center;
        text-align: center;
      }
    }
  }
}
@primary-color: #307097;