.content-table {
    overflow-x: auto;
    overflow-y: auto;
    height: 40rem;
}

.styled-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
    background-color: #009879;
    color: #ffffff;
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table tbody tr {
    border-bottom: 1px solid rgb(177, 177, 177);
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #ffffff;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #aaaaaa;
}

.styled-table tbody tr.active-row {
    font-weight: lighter;
    font-size: small;
    color: #000000;
}

.active-row:hover {
    background-color: #f9f9f9;
}

@media screen and (max-width: 600px) {
    .content-table {
        width: 180px;
        height: 300px;
        overflow: scroll;
    }

    .table-head {
        display: none;
    }

    .styled-table {
        display: block;
        overflow-x: auto;
    }

    .active-row .table-td:first-child {
        font-weight: lighter;
        font-size: medium;
    }

    .table-body .table-td {
        display: block;
        text-align: center;
    }

    .table-body .table-td {
        display: block;
        text-align: center;
    }
}

@media screen and (max-width: 320px) {
    .content-table {
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .styled-table {
        display: block;
        overflow-x: auto;
    }
}