.container-works {
  .list-works {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
    gap: 1.5rem;
    user-select: none;

    .work {
      position: relative;
      background: #FFFFFF;
      border-radius: 6px;
      word-wrap: break-word;
      text-align: left;
      min-height: 7rem;
      padding: 1.6rem;
      box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.04);

      h2 {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 1.15em;
        color: #282323;
      }

      i {
        position: absolute;
        bottom: 1rem;
        right: 1.5rem;
        font-size: 1.1em;
        color: var(--teal);
      }
    }

    .work:hover {
      transform: scale(1.01);
      cursor: pointer;
    }

    .disabled {
      box-shadow: none;

      i {
        filter: grayscale(100%);
      }

      &:hover {
        transform: none;
      }
    }

    .disabled::after {
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: .5;
      background-color: var(--gray6);
      position: absolute;
      z-index: 1;
      cursor: not-allowed;
    }
  }
}

.container-options-home {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15.875rem, 1fr));
  gap: 1.5rem;
  user-select: none;

  .item {
    background-color: white;
    padding: 1.8rem;
    height: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1.3rem;
    word-wrap: break-word;
    overflow: hidden;
    border-radius: 6px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.04);
    position: relative;

    h3 {
      font-weight: bold;
      font-size: 1.4em;
    }

    p {
      font-size: 1em;
      color: #9497A1;
    }

    i {
      font-size: 1.8rem;
      color: var(--teal);
    }

    .icon {
      height: 2rem;
      width: 2rem;
    }

    &:hover {
      transform: scale(1.03);
      cursor: pointer;
    }

    .lock-icon {
      position: absolute;
      bottom: 2rem;
      right: 2rem;

    }
  }

  .disabled {
    box-shadow: none;

    i {
      filter: grayscale(100%);
    }

    &:hover {
      transform: none;
    }
  }

  .disabled::after {
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .5;
    background-color: var(--gray6);
    position: absolute;
    z-index: 1;
    cursor: not-allowed;
  }
}

.container-options-clients {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 19rem));
  grid-template-rows: 250px;
  gap: 1.5rem;
  user-select: none;

  .item {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    gap: 15px;
    background: #FFFFFF;
    border-radius: 6px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.04);
    word-wrap: break-word;

    .img-content {
      width: 100%;
      height: 100px;
      aspect-ratio: 1/1;
    }

    h3 {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: var(--darkest);
      text-transform: uppercase;
    }

    .obras {
      width: 220px;
      height: 24px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #9497A1;
    }

    &:hover {
      transform: scale(1.0005);
      cursor: pointer;
    }
  }

  .disabled {
    box-shadow: none;

    .img-content {
      filter: grayscale(100%);
    }

    &:hover {
      transform: none;
    }
  }

  .disabled::after {
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .5;
    background-color: var(--gray6);
    position: absolute;
    z-index: 1;
    cursor: not-allowed;
  }
}
@primary-color: #307097;